import React from 'react';
import Main from './components/Main';

import {AuthProvider} from 'jnpsoft-react-utilities/dist/AppBar';

const App = () => {

  return (
    <AuthProvider 
      render={(props) => <Main setApplicationRoutes={props.setApplicationRoutes}/>}
      showUtilityBar={false}/>
  );
}

export default App;