import React, {useState, useEffect, useContext } from 'react';
import LinkIcon from '@mui/icons-material/Link';
import { JnpDivTable, DivTableStyles } from "jnpsoft-react-utilities/dist/JnpTable";
import {getGlobalAcesArticleLinkages} from '../../services/ApiService';
import {DateCell} from 'jnpsoft-react-utilities/dist/JnpTable/Commons';
import { createAccessorArrayDescription } from '../utilities';
import {EditBarContext, ActionBarContext} from '../../context';
import { configureActionBar } from '../../services/ActionBarService';

const createArticleColumnDefs = () => ([
	{
		Header: 'Part #',
		accessor: 'articleNumber',
        width: 160,
	},
	{
		Header: 'Product Line',
		accessor: 'productLineName',
        width: 220,
	},
    {
		Header: 'Brand',
		accessor: 'brandName',
		align: 'left',
        width: 160,
	},
    {
        Header: 'Equipment Model',
        accessor: 'equipmentModel',
        width: 180,
    },
    {
        Header: 'Equipment Mfr',
        accessor: 'equipmentMfr',
        width: 180,
    },
    {
        Header: 'Vehicle Type',
        accessor: 'vehicleType',
        width: 180,
    },
    {
        Header: 'Region',
        accessor: 'region',
        width: 150,
    },
    {
        Header: 'Position',
        accessor: 'position',
        width: 180,
    },
    {
        Header: 'Engine Block',
        accessor: 'engineBlock',
        width: 180,
    },
    {
        Header: 'Engine Bore / Stroke',
        accessor: 'engineBoreStroke',
        width: 180,
    },
    {
        Header: 'Valves',
        accessor: 'valves',
        width: 180,
    },
    {
        Header: 'Vin',
        accessor: 'vin',
        width: 180,
    },
    {
        Header: 'Engine Version',
        accessor: 'engineVersion',
        width: 180,
    },
    {
        Header: 'Designation',
        accessor: 'designation',
        width: 180,
    },
    {
        Header: 'Power Output',
        accessor: 'powerOutput',
        width: 180,
    },
    {
        Header: 'Fuel System Control Type',
        accessor: 'fuelSystemControlType',
        width: 200,
    },
    {
        Header: 'Fuel Delivery Type',
        accessor: 'fuelDeliveryType',
        width: 180,
    },
    {
        Header: 'Fuel Delivery SubType',
        accessor: 'fuelDeliverySubType',
        width: 180,
    },
    {
        Header: 'Fuel System Design',
        accessor: 'fuelSystemDesign',
        width: 180,
    },
    {
        Header: 'Cylinder Head Type',
        accessor: 'cylinderHeadType',
        width: 180,
    },
    {
        Header: 'Aspiration',
        accessor: 'aspiration',
        width: 180,
    },
    {
        Header: 'Ignition System Type',
        accessor: 'ignitionSystemType',
        width: 180,
    },
    {
        Header: 'Fuel Type',
        accessor: 'fuelType',
        width: 180,
    },
    {
        Header: 'Notes',
        accessor: 'notes',
        Cell: createAccessorArrayDescription,
        width: 500
    },
	{
		Header: 'Life Cycle',
		accessor: 'lifeCycleName',
		align: 'left',
        width: 220,
	},
	{
		Header: 'Mfr Labels',
		accessor: 'mfrLabelName',
		align: 'left',
        width: 220,
	},
	{
		Header: 'Last modification',
		Cell: DateCell,
		accessor: 'date',
	},
]);

const SearchScreen = () => {

    const [globalLinkages, setGlobalLinkages] = useState([]);
    const {setEditBarOpen} = useContext(EditBarContext);
    const {actionInfos, setActionInfos, setActionStaticContent} = useContext(ActionBarContext);
    

    useEffect(() => {        
        getGlobalAcesArticleLinkages(setGlobalLinkages);
        setEditBarOpen(false);
        return configureActionBar([], actionInfos, setActionInfos, setActionStaticContent );
    },[]);

    return (
        <div style={{ height: 'calc(100% - 20px)' }}>
            <DivTableStyles>
                <JnpDivTable
                    columns={createArticleColumnDefs()}
                    data={globalLinkages}
                    disableSelect = {() => true}
                    setSelectedRows={rows => {}}
                    keyPropertyId="search-screen-id"
                    label="Applications"
                    title="Parts - Applications"
                    uniqueId="search-screen-table"
                    titleIcon={<LinkIcon />}
                    defaultSortColumn={"articleNumber"}
                    selectedBackgroundColor='#BEBEBE'
                    customRowProps={row => ({
                        style: {
                            backgroundColor: row.original.isCustom ? 'yellow' : null,
                        }
                    })
                }
                />
            </DivTableStyles>
        </div>
    );
}

export default SearchScreen;