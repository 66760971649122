import React, {useContext, useEffect} from 'react';
import Drawer from '@mui/material/Drawer';
import {EditBarContext} from '../context';

export const EditBarWidth = 400;

const EditBar = ({open = true, children}) => {

	const {setEditBarOpen} = useContext(EditBarContext);

	useEffect(() => {
		setEditBarOpen(open);
	},[open])

	const drawerStyle  = {
		"& .MuiDrawer-paper": 
			open ? {
				width: EditBarWidth,
				paddingTop: 8,
				zIndex: 1,
				backgroundColor: '#F5F5F5',
				transition: 'width 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, margin 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
	
			}
			: {
				width: 0,
				zIndex: 1,
				backgroundColor: '#F5F5F5',
				transition: 'width 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, margin 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
			}
		};

    return (						
        <Drawer 
			anchor='right'
			open={open}
			variant="persistent"
            sx={drawerStyle}
        >							
			<div style={{		
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'flex-end'}}></div>
			<div style={{
				padding: 16,
				overflowY: 'auto',
				paddingBottom: 56,
			}}>
				{children}
			</div>
        </Drawer>	
    );
}

export default EditBar;