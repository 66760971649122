export const errorTypes = ({

  1: 'Missing Field',
  2: 'Invalid Equipment Base',
  3: 'Invalid Engine',
  4: 'Invalid Engine Combination',
  5: 'Invalid Region Combination',
  6: 'Invalid Product Line',
  7: 'Invalid Position',
  8: 'Duplicate',
  9: 'Unknown Value',
  10: 'Unknown Qualifier',
  11: 'Invalid Qualifier Param',
  12: 'Too Many Qualifier Params',
  13: 'Insufficient Qualifier Params',
  14: 'Qualifier Value Empty',
  15: 'Qualifier Value Too Long',
  16: 'Invalid Qualifier Param Uom',
});

export const errorMessageFactory = (error) => {

  if (!!error?.isValidAsCustom) {
    return 'Contains valid custom application information';
  }

  switch (error?.errorType) {
    case 1: return (<span>{`This field is missing from your load sheet: `}<b>{error.properties.MissingField ?? 'Unknown'}</b></span>)
    
    case 2: 
      const equipmentBaseMessages = [
        <span>{`Equipment Mfr: `}<b>{`${error?.properties?.EquipmentMfrValue ?? 'Unknown'}${!!error?.properties?.EquipmentMfrId ? ` (Id = ${error?.properties?.EquipmentMfrId})` : ' (Unknown Id)'}`}</b></span>,
        <span>{`Equipment Model: `}<b>{`${error?.properties?.EquipmentModelValue ?? 'Unknown'}${!!error?.properties?.EquipmentModelId ? ` (Id = ${error?.properties?.EquipmentModelId})` : ' (Unknown Id)'}`}</b></span>,
        <span>{`Vehicle Type: `}<b>{`${error?.properties?.VehicleTypeValue ?? 'Unknown'}${!!error?.properties?.VehicleTypeId ? ` (Id = ${error?.properties?.VehicleTypeId})` : ' (Unknown Id)'}`}</b></span>
      ]

      return (
        <span>
          Invalid Equipment base:
          <ul>
            { equipmentBaseMessages.map((x, i) => <li key={i}>{x}</li>) }
          </ul>
        </span>
      )

    case 4: 
      const engineErrors = (error?.properties?.errorsAndPossibles ?? [])
        .sort((a, b) => Object.keys(a?.error)?.length > Object.keys(b?.error)?.length ? 1 : -1)
        .map((x, i) => (
          <span>
            {`Solution ${i + 1}`}
            <ul>
            {
              Object.entries(x.error)
              .map((y, i) => (<li key={i}>{`Replace ${y[0]}: ${y[1].value ?? 'Unknown'} (Id = ${y[1].id ?? 'Unknown'}) by ${x.possibles[y[0]].value} (Id = ${x.possibles[y[0]].id})`}</li>))
            }
            </ul>
          </span>
        ))
      return (
        <span>
          Please apply one of the suggestion:
          <ul>
            { engineErrors.map((x, i) => <li key={i}>{x}</li>) }
          </ul>
        </span>
      )

    case 5:
      return <span>Current region: <b>{`${error?.properties?.regionValue ?? 'Unknown'} (Id = ${error?.properties?.regionId})`}</b> is invalid for the current engine configuration</span>

    case 6:
      const productLine = error?.properties?.invalidName
      return !!productLine ? (<span>{`Invalid Value for Product Line: `}<b>{productLine}</b></span>) : (<span>{`Invalid ID for Product Line: `}<b>{error?.properties?.invalidId}</b></span>)
    
    case 7:
      const position = error?.properties?.invalidName
      return !!position ? (<span>{`Invalid Value for Position: `}<b>{position}</b></span>) : (<span>{`Invalid ID for Position: `}<b>{error?.properties?.invalidId}</b></span>)
    
    case 8:
      return 'Application already exists';
    
    case 9: 
      const errorProperties = Object.entries(error?.properties ?? {});
      const unknownValuesMessages = 
        errorProperties
        .filter(x => x[0]?.endsWith('~Id'))
        .map((x, i) => {
          const vcdbTable = x[0].split('~Id')[0];

          const value = error?.properties[`${vcdbTable}~Value`];
          const possibleValues = error?.properties[`${vcdbTable}~Possible`] ?? [];
          return (
            <div key={i}>
              {
                !!value ? 
                <span>
                  {`Unknown Value for ${vcdbTable}: `}<b>{value}</b>
                </span>
                : 
                <span>
                  {`Unknown ID for ${vcdbTable}: `}<b>{x[1]}</b>
                </span>
              }
              <div>
              {
                !!possibleValues?.length ? 
                  <>
                    Possible Values: 
                    <ul>
                    {
                      possibleValues.map((y, i) => <li key={i}>{`${y?.value ?? 'Unknown'} (Id = ${y?.id ?? 'Unknown'})`}</li>)
                    }
                    </ul>
                  </>
                : 'No possible value found'
              }
              </div>
            </div>)
        })
      return (<span>
        {unknownValuesMessages.map((x, i) => (<div key={i}>{x}</div>) )}
      </span>)
    case 10: {
      return getUnknownQualifierIdError(error);
    }
    case 11: {
      return getInvalidQualifierParamError(error);
    }
    case 12: {
      return getTooManyQualifierParamError(error);
    }
    case 13: {
      return getInsufficientNumberOfQualifierParamError(error);
    }
    case 14: {
      return getQualifierValueIsEmptyError(error);
    }
    case 15: {
      return getQualifierValueIsTooLongError(error);
    }
    case 16: {
      return getInvalidQualifierParamUomError(error);
    }
    default: return 'Unknown error message'
  }
}


export const errorMessageFactoryCSV = (error) => {

  if (!!error?.isValidAsCustom) {
    return 'Contains valid custom application information';
  }
  
  switch (error?.errorType) {
    case 1: return (`This field is missing from your load sheet: ${error.properties.MissingField ?? 'Unknown'}`)
    
    case 2: 
      const equipmentBaseMessages = [
        `Equipment Mfr: ${error?.properties?.EquipmentMfrValue ?? 'Unknown'}${!!error?.properties?.EquipmentMfrId ? ` (Id = ${error?.properties?.EquipmentMfrId})` : ' (Unknown Id)'}`,
        `Equipment Model: `+ `${error?.properties?.EquipmentModelValue ?? 'Unknown'}${!!error?.properties?.EquipmentModelId ? ` (Id = ${error?.properties?.EquipmentModelId})` : ' (Unknown Id)'}`,
        `Vehicle Type: ${error?.properties?.VehicleTypeValue ?? 'Unknown'}${!!error?.properties?.VehicleTypeId ? ` (Id = ${error?.properties?.VehicleTypeId})` : ' (Unknown Id)'}`
      ]
  
      return (
          `Invalid Equipment base:  ${equipmentBaseMessages.map((x, i) => {return '\r\n' + x}) }`
      )
  
    case 4: 
      const engineErrors = (error?.properties?.errorsAndPossibles ?? [])
        .sort((a, b) => Object.keys(a?.error)?.length > Object.keys(b?.error)?.length ? 1 : -1)
        .map((x, i) =>  {
          return (
            `Solution ${i + 1}
            ${
              Object.entries(x.error)
              .map((y, i) => {return (`\r\n Replace ${y[0]}: ${y[1].value ?? 'Unknown'} (Id = ${y[1].id ?? 'Unknown'}) by ${x.possibles[y[0]].value} (Id = ${x.possibles[y[0]].id}}`)})
            }
            `
          )
        })
      return (
        `Please apply one of the suggestion: ${engineErrors.map((x, i) => {return '\r\n' + x}) }`
      )
  
    case 5:
      return `${error?.properties?.regionValue ?? 'Unknown'} (Id = ${error?.properties?.regionId})} is invalid for the current engine configuration `
  
    case 6:
      const productLine = error?.properties?.invalidName
      return !!productLine ? (`Invalid Value for Product Line: ${productLine}`) : (`Invalid ID for Product Line: ${error?.properties?.invalidId}`)
    
    case 7:
      const position = error?.properties?.invalidName
      return !!position ? (`Invalid Value for Position: ${position}`) : (`Invalid ID for Position: ${error?.properties?.invalidId}`)
    
    case 8:
      return 'Application already exists';
    
    case 9: 
      const errorProperties = Object.entries(error?.properties ?? {});
      const unknownValuesMessages = 
        errorProperties
        .filter(x => x[0]?.endsWith('~Id'))
        .map((x, i) => {
          const vcdbTable = x[0].split('~Id')[0];
  
          const value = error?.properties[`${vcdbTable}~Value`];
          const possibleValues = error?.properties[`${vcdbTable}~Possible`] ?? [];
          return (
            
              `${!!value ? 
                `Unknown Value for ${vcdbTable}: ${value}`
                : 
                `Unknown ID for ${vcdbTable}: ${x[1]}`
              }
              
              ${
                !!possibleValues?.length ? 
                `Possible Values: ${possibleValues.map((y, i) =>{ return `\r\n ${y?.value ?? 'Unknown'} (Id = ${y?.id ?? 'Unknown'})`})}`
                : 
                `No possible value found`
              }`
          )
        })
      return (
        `${unknownValuesMessages.map((x, i) =>  {return `\r\n ${x}` })}`
     )
    case 10: {
      return getUnknownQualifierIdError(error);
    }
    case 11: {
      return getInvalidQualifierParamError(error);
    }
    case 12: {
      return getTooManyQualifierParamError(error);
    }
    case 13: {
      return getInsufficientNumberOfQualifierParamError(error);
    }
    case 14: {
      return getQualifierValueIsEmptyError(error);
    }
    case 15: {
      return getQualifierValueIsTooLongError(error);
    }
    case 16: {
      return getInvalidQualifierParamUomError(error);
    }
    default: return 'Unknown error message'
  }
}
const getUnknownQualifierIdError = (error) => {
  const qualifierId = error?.properties?.["qualifierId"];
  return `Unknown Qualifier (Id = ${qualifierId})`;
}

const getInvalidQualifierParamError = (error) => {
  const errorProperties10 = Object.entries(error?.properties ?? {});
  const qualifierId = error?.properties?.["qualifierId"];
  const parameter = errorProperties10.find(x => x[0]?.startsWith("param~"))[1];

  return `Invalid value "${parameter?.Value}" for Qualifier (Id = ${qualifierId}) Parameter #${parameter?.Id}`;
}

const getTooManyQualifierParamError = (error) => {
  const qualifierId = error?.properties?.["qualifierId"];
  return `There's too many parameters supplied for this Qualifier (Id = ${qualifierId})`;
}

const getInsufficientNumberOfQualifierParamError = (error) => {
  const qualifierId = error?.properties?.["qualifierId"];
  return `There's not enough parameters supplied for this Qualifier (Id = ${qualifierId})`;
}

const getQualifierValueIsEmptyError = (error) => {
  const qualifierId = error?.properties?.["qualifierId"];
  return `Qualifier (Id = ${qualifierId}) value must not be empty`;
}

const getQualifierValueIsTooLongError = (error) => {
  const qualifierId = error?.properties?.["qualifierId"];
  return `Qualifier (Id = ${qualifierId}) value exceed the 250 characters limit`;
}

const getInvalidQualifierParamUomError = (error) => {
  const errorProperties10 = Object.entries(error?.properties ?? {});
  const qualifierId = error?.properties?.["qualifierId"];
  const parameter = errorProperties10.find(x => x[0]?.startsWith("param~"))[1];

  return `Invalid uom "${parameter?.Uom}" for Qualifier (Id = ${qualifierId}) Parameter #${parameter?.Id}`;
}
