import React, { useState, useEffect } from 'react';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import TemplateManage from '../TemplateManage';
import { createKeyValueObject, createFilter, createDescription } from '../utilities';
import { 
	getAllArticles, 
	getAllProductLines, 
	getAllBrands, 
	getAllLifeCycles,
	getAllMfrLabels, 
	postArticle, 
	deleteArticle } from '../../services/ApiService';
import { useNavigate } from 'react-router-dom';

import {DateCell} from 'jnpsoft-react-utilities/dist/JnpTable/Commons';
import Grid from '@mui/material/Grid';
import GeneralAutocomplete from "../GeneralAutocomplete";
import GeneralTextField from "../GeneralTextField";
import { Button } from '@mui/material';

const createArticleColumnDefs = (prods, brands, mfrLabels, lifes) => ([
	{
		Header: 'Part #',
		accessor: 'articleNumber',
		width: 160,
	},
	{
		Header: 'Product Line',
		accessor: 'productLineId',
		Cell: createDescription(prods),
		filter: createFilter(prods),
		width: 220,
	},
	{
		Header: 'Brand',
		accessor: 'brandId',
		align: 'left',
		Cell: createDescription(brands),
		filter: createFilter(brands),
		width: 160,
	},
	{
		Header: 'Life Cycle',
		accessor: 'lifeCycleId',
		align: 'left',
		Cell: createDescription(lifes),
		filter: createFilter(lifes),
		width: 200,
	},
	{
		Header: 'Mfr Labels',
		accessor: 'mfrLabelId',
		align: 'left',
		Cell: createDescription(mfrLabels),
		filter: createFilter(mfrLabels),
		width: 200,
	},
	{
		Header: 'Last modification',
		Cell: DateCell,
		accessor: 'date',
	},
]);

const createArticle = (articleId = -1, articleNumber = '') => ({
	articleId,
	articleNumber
});

const MyProducts = () => {

	const navigate = useNavigate();

	const [products, setProducts] = useState([]);
	const [brands, setBrands] = useState([]);
	const [lifeCycles, setLifeCycles] = useState([]);
	const [mfrLabels, setMfrLabels] = useState([]);

    useEffect(() => {        
        getAllBrands(setBrands);
        getAllProductLines(setProducts);
        getAllMfrLabels(setMfrLabels);
        getAllLifeCycles(setLifeCycles);
    },[]);

	const validations = [
        (article) => {
            const error = article.articleNumber && article.articleNumber.length ? undefined : "Auto Care Header name is required";
            return {articleNumber: error};
        },
        (article) => {
            const error = article.brandId ? undefined : "Brand is required";
            return {brand: error};
        },
				(article) => {
					const error = article.productLineId ? undefined : "Product line is required";
					return {productLine: error};
			}
    ]

	const setArticle = (article, setArticle) => setArticle({...article});


	const handleSetBrandId = (id, article, setArticle) => {
        setArticle({...article, brandId: id});
    }

	const handleSetProduct = (id, article, setArticle) => {
        setArticle({...article, productLineId: id});
    }

	const handleSetMfrLabel = (id, article, setArticle) => {
        setArticle({...article, mfrLabelId: id});
    }

	const handleSetLifeCycle = (id, article, setArticle) => {
        setArticle({...article, lifeCycleId: id});
    }

    return (
		<TemplateManage
		keyPropertyId="articleId"
		label="Part"
		columnDefs={createArticleColumnDefs(
			    createKeyValueObject(products, "productLineId", "productLineName"), 
                createKeyValueObject(brands, "id", "name"), 
                createKeyValueObject(mfrLabels, "mfrLabelId", "mfrLabelName"), 
                createKeyValueObject(lifeCycles, "lifeCycleId", "lifeCycleName"))}
		createDefaultObject={createArticle}
		getAllObjects={getAllArticles}
		postObject={postArticle}
		deleteObject={deleteArticle}
		validations={validations}
		title="Parts"
		uniqueId="my_product_table"
		titleIcon={<LibraryBooksIcon />}
		defaultSortColumn={"articleNumber"}
		renderInputs={({editValue, setEditValue, errors}) => { 

			const selectedProduct = products.find(x => x.productLineId === editValue.productLineId);
			const selectedBrand = brands.find(x => x.id === editValue.brandId);
			const selectedMfrLabel = mfrLabels.find(x => x.mfrLabelId === editValue.mfrLabelId);
			const selectedLifecycle = lifeCycles.find(x => x.lifeCycleId === editValue.lifeCycleId);
			return (
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<GeneralTextField 
						keyPropertyName="articleNumber"
						label="Part Number"
						itemValue={editValue}
						setItemValue={v => setArticle(v, setEditValue)}
						handleError={ (key) => !!errors[key] }
						getHelperText={ (key) => errors[key] }
						/>
					</Grid>
					<Grid item xs={12}>
						<GeneralAutocomplete 
								id="brand-autocomplete"
								options={brands}
								value={selectedBrand}
								keyId="id"
								keyName="name"
								setId={v => handleSetBrandId(v, editValue, setEditValue)}
								label="Brand"
								handleError={ (key) => !!errors['brand'] }
								getHelperText={ (key) => errors['brand'] }
                            />
							</Grid>
								<Grid item xs={12}>
										<GeneralAutocomplete
												options={products}
												value={selectedProduct}
												keyId="productLineId"
												keyName="productLineName"
												setId={v => handleSetProduct(v, editValue, setEditValue)}
												label="Product"
												handleError={ (key) => !!errors['productLine'] }
												getHelperText={ (key) => errors['productLine'] }
										/>
								</Grid>
								<Grid item xs={12}>
										<GeneralAutocomplete
												options={mfrLabels}
												value={selectedMfrLabel || ''}
												keyId="mfrLabelId"
												keyName="mfrLabelName"
												setId={v => handleSetMfrLabel(v, editValue, setEditValue)}
												label="MfrLabel"
										/>
								</Grid>
								<Grid item xs={12}>
										<GeneralAutocomplete
												options={lifeCycles}
												value={selectedLifecycle || ''}
												keyId="lifeCycleId"
												keyName="lifeCycleName"
												setId={v => handleSetLifeCycle(v, editValue, setEditValue)}
												label="LifeCycle"
										/>
								</Grid>
								<Grid item xs={12}>
										<Button disabled={editValue.articleId <= 0} onClick={() => navigate(`/manage-linkages/${editValue.articleId}`)} variant="contained"  sx={!editValue.articleId <= 0? {backgroundColor:'#ff562b',color:'white',mt:2}: {mt:2}}>Applications</Button>
								</Grid>
							</Grid>)
			}}
		/>
    );
}

export default MyProducts;