export const configureActionBar = (newActions, actionInfos, setActionInfos, setActionStaticContent = () => {}, actionStaticContent) => {
    setActionStaticContent(actionStaticContent);
    setActionInfos({...actionInfos, actions: newActions, actionClicked: null});
    return () => {setActionInfos({actions: [], actionClicked: null})};
}

export const executeAction = (onAction, actionInfos, setActionInfos) => {
    onAction();
    setActionInfos({...actionInfos, actionClicked: null});
}

export const configureDisabled = (name, disabled, actionInfos, setActionInfos) => {

    const actionIndex = actionInfos.actions.findIndex(x => x.name === name);

    if (actionIndex > -1)
    {
        const currentDisableStatus = actionInfos.actions[actionIndex]?.disabled;

        if (currentDisableStatus !== disabled)
        {
            const newActions = [...actionInfos.actions];
            newActions[actionIndex].disabled = disabled;
            setActionInfos({
                ...actionInfos,
                actions: newActions,
                actionClicked: null,
            })
        }
    }
}