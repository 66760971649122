import DashboardIcon from '@mui/icons-material/Dashboard';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import BrightnessAutoIcon from '@mui/icons-material/BrightnessAuto';
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import PermDataSettingIcon from '@mui/icons-material/PermDataSetting';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import ManageProductLine from './product/ManageProductLine';
import ManageMfrLabel from './mfrlabel/ManageMfrLabel';
import ManageLifeCycle from './lifecycle/ManageLifeCycle';
import ManageLinkage from './linkage/ManageLinkage';
import ManageAutocareHeader from './autocare-header/ManageAutocareHeader';
import ManageBrand from './brand/ManageBrand';
import ManageImportConfiguration from './import-config/ManageImportConfiguration';
import ManageExportConfiguration from './export-config/ManageExportConfiguration';
import LinkIcon from '@mui/icons-material/Link';
import MyProducts from './article/MyProducts';
import SearchScreen from './search-screen/SearchScreen';
import Export from './export/Export';
import Import from './import/Import';
import ManageCustomAcesAttribute from './custom-aces-attribute/ManageCustomAcesAttribute';
import TourIcon from '@mui/icons-material/Tour';
import Revalidation from './revalidation/Revalidation';

export const ManageProductRoute = "/manage-products";
export const ManageLinkagesRoute = "/manage-linkages";
export const ExportConfigurationRoute = "/export-configuration";
export const ImportConfigurationRoute = "/import-configuration";
export const ExportRoute = "/exports";
export const ImportRoute = "/imports";

export const HomeLink = {route: "", name: "Parts", icon: () => <LibraryBooksIcon />, target: () => <MyProducts />}

export const Links = [
    {route: "search-screen", name: "Search Screen", icon: () => <LinkIcon />, target: (props) => <SearchScreen  {...props}/>},
	{route: "manage-products", name: "Product Lines", icon: () => <DashboardIcon />, target: (props) => <ManageProductLine {...props}/>},
	{route: "manage-mfrlabels", name: "Mfr Labels", icon: () => <EmojiTransportationIcon/>, target: (props) => <ManageMfrLabel {...props}/>},
	{route: "manage-lifecycles", name: "Life Cycles", icon: () => <RotateLeftIcon />, target: (props) => <ManageLifeCycle {...props}/>},
    {route: "autocare-header-overview", name: "Auto Care Xml Header", icon: () => <BrightnessAutoIcon />, target: (props) => <ManageAutocareHeader {...props}/>},
    {route: "manage-brands", name: "Brands", icon: () => <BrandingWatermarkIcon />, target: (props) => <ManageBrand {...props}/>},
    {route: "imports", name: "Import / Convert", icon: () => <ImportExportIcon />, target: (props) => <Import {...props}/>},
    {route: "exports", name: "Exports", icon: () => <PermDataSettingIcon />, target: (props) => <Export {...props}/>},
    {route: "custom-aces-attributes", name: "Custom Attributes", icon: () => <TourIcon />, target: (props) => <ManageCustomAcesAttribute {...props}/>},
    {route: "revalidation", name: "Revalidation", icon: () => <LibraryAddCheckIcon />, target: (props) => <Revalidation {...props}/>},
];

export const Routes = [
	{route: `${ManageLinkagesRoute}/:articleId`, target: () => <ManageLinkage />},
    {route: ExportConfigurationRoute, name: "Export Configuration", icon: () => <PermDataSettingIcon />, target: (props) => <ManageExportConfiguration {...props}/>},
    {route: ImportConfigurationRoute, name: "Import Configuration", icon: () => <ImportExportIcon />, target: (props) => <ManageImportConfiguration {...props}/>},
];