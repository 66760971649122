import React, {useRef, useContext} from 'react';
import {getFiles, download, deleteFile} from '../../services/ApiService';
import {DateCell} from 'jnpsoft-react-utilities/dist/JnpTable/Commons';
import TemplateManage from "../TemplateManage";
import {downloadFile} from "../utilities";
import { useNavigate } from "react-router-dom";
import {ExportConfigurationRoute} from "../routes";
import { SnackBarContext } from '../../context';

const FileTypes = {
    1: 'ACES XML',
    2: 'ACES EXCEL',
    3: "CONVERT EXCEL TO XML",
    4: "CONVERT XML TO EXCEL",
}

const columnDefinitions = [
    {
        Header: 'Export Name',
        accessor: 'name'
    },
    {
        Header: 'User Name',
        accessor: 'username'
    },
    {
        Header: 'File Name',
        accessor: 'filename'
    },
    {
        Header: 'File Type',
        accessor: 'fileType',
        Cell: ({value}) => FileTypes[value],
    },
    {
        Header: 'Date',
        accessor: 'date',
        Cell: DateCell,
    }
];

const Export = ({name, icon}) => { 

    const navigate = useNavigate();
    const templateMangeRef = useRef(null);
    const {setSnackBarInfo} = useContext(SnackBarContext);
    
    const handleRefresh = () => {
        if (templateMangeRef) {
            templateMangeRef.current.refreshData();
        }
    };

    const handleExport = () => navigate(ExportConfigurationRoute);

    const handleDownload = (rows) => {
        const onError = (error) => {
            setSnackBarInfo({open: true, message: 'Unable to download the export. Please contact JNPOpticat support.', severity: "error"});
        };
        (rows || []).forEach(({dataSupplierFileId}) => {
            download(dataSupplierFileId, response => {
                downloadFile(response);
            }, onError);
        });
    };

    const actions = [
        {
            label: "New Export",
            name: "newExport",
            onAction: handleExport,
        },
        {
            label: "Download",
            name: "download",
            disableOnEmptySelection: true,
            onAction: handleDownload,
        },
        {
            label: "Refresh",
            name: "refresh",
            onAction: handleRefresh,
        },
    ];

    return (
        <React.Fragment>
            <TemplateManage
                keyPropertyId="dataSupplierFileId"
                ref={templateMangeRef}
                label={name}
                title={name}
                titleIcon={icon()}
                columnDefs={columnDefinitions}
                getAllObjects={getFiles}
                deleteObject={deleteFile}
                defaultSortColumn={{id: "date", desc: true}}
                additionalActions={actions} />
        </React.Fragment>
    );
}

export default Export;