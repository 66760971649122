import React, {useState, useContext} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {postCustomAcesAttribute} from '../../services/ApiService';
import { SnackBarContext } from '../../context';
import GeneralTextField from '../GeneralTextField';

const AddAcesCustomAttributeDialog = ({open, setOpen, attributeFieldName, executeAction= () => {}}) => {

    const {setSnackBarInfo} = useContext(SnackBarContext);

    const [attributeFieldValue, setAttributeFieldValue] = useState({value: ''});

    const handleClose = () => {
        setAttributeFieldValue({value: ''});
        setOpen(false);
    };

    const handleAction = () => {
        saveAcesCustomAttribute();
    };
    const saveAcesCustomAttribute = () => {
      if (attributeFieldName.length && attributeFieldValue?.value.length) {
        postCustomAcesAttribute({attributeFieldName, AttributeValue: attributeFieldValue.value},
          d => {
              setSnackBarInfo({
              open: true,
              message: 'New Custom Attribute added.',
              severity: "success"
            });
            executeAction(d?.data);
            handleClose();
        },
        e => {
          setSnackBarInfo({
            open: true,
            message: 'Custom Attribute already exist',
            severity: "error"
          });
          handleClose();
        });
      }
    }

    return (
        <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby={`addAcesCustomAttribute-dialog-title`}
        aria-describedby={`addAcesCustomAttribute-dialog-description`}
        >
            <DialogTitle id={`addAcesCustomAttribute-dialog-title`}>Aces Custom Attribute</DialogTitle>
            <DialogContent>
                <DialogContentText id={`addAcesCustomAttribute-dialog-description`}>
                    {`Add Aces Custom Attribute for [${attributeFieldName}]`}
                </DialogContentText>
                <GeneralTextField 
                  keyPropertyName="value"
                  label='Attribute Field Value'
                  itemValue={attributeFieldValue}
                  setItemValue={setAttributeFieldValue}
                  handleError={() => Boolean(attributeFieldValue?.length)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleAction} color="primary">
                Confirm
                </Button>
                <Button onClick={handleClose} color="primary">
                Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AddAcesCustomAttributeDialog;