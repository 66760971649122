import React, {useState, useEffect, useContext, useRef } from 'react';
import LinkIcon from '@mui/icons-material/Link';
import {getRevalidationErrors, getRevalidationLastReport, postRevalidate, getSearchFacets, getPositionForId, deleteAcesLinkages} from '../../services/ApiService';
import {DateCell, convertISOtoLocal} from 'jnpsoft-react-utilities/dist/JnpTable/Commons';
import { createAccessorArrayDescription } from '../utilities';
import {SnackBarContext, EditBarContext, ActionBarContext} from '../../context';
import { errorMessageFactory, errorTypes } from '../../services/AcesErrorService';
import { configureActionBar } from '../../services/ActionBarService';
import Typography from '@mui/material/Typography';
import TemplateManage from '../TemplateManage';
import LinkageOverview from '../linkage/LinkageOverview';
import {isDisable, handleSelect, acesAttributeMatchTypeRender, doPostLinkage} from '../../services/LinkageOverviewService';

const RevalidationReportStatus = status => {
    switch(status) {
        case 0 : return ' It\'s currently queued ⚠️';
        case 1 : return ' It\'s currently processing ⚠️';
        case 2 : return 'finished successfuly ✅';
        case 3 : return 'finished with errors ⛔';
        default: return 'Unknown';
    }
}

const createArticleColumnDefs = () => ([
    {
		Header: 'Error Type',
		accessor: 'errorType',
        width: 160,
    },
    {
		Header: 'Error Message',
		accessor: 'errorMessage',
        width: 300,
    },
	{
		Header: 'Revalidation Date',
		Cell: DateCell,
		accessor: 'revalidationDate',
	},
	{
		Header: 'Part #',
		accessor: 'articleNumber',
        width: 160,
	},
	{
		Header: 'Product Line',
		accessor: 'productName',
        width: 220,
	},
    {
		Header: 'Brand',
		accessor: 'brandName',
		align: 'left',
        width: 160,
	},
    {
        Header: 'Equipment Model',
        accessor: 'acesArticleLinkage.equipmentModel.value',
        width: 180,
    },
    {
        Header: 'Equipment Mfr',
        accessor: 'acesArticleLinkage.equipmentMfr.value',
        width: 180,
    },
    {
        Header: 'Vehicle Type',
        accessor: 'acesArticleLinkage.vehicleType.value',
        width: 180,
    },
    {
        Header: 'Region',
        accessor: 'acesArticleLinkage.region.value',
        width: 150,
    },
    {
        Header: 'Position',
        accessor: 'acesArticleLinkage.positionName',
        width: 180,
    },
    {
        Header: 'Engine Block',
        accessor: 'acesArticleLinkage.engineBlock.value',
        width: 180,
    },
    {
        Header: 'Engine Bore / Stroke',
        accessor: 'acesArticleLinkage.engineBoreStroke.value',
        width: 180,
    },
    {
        Header: 'Valves',
        accessor: 'acesArticleLinkage.valves.value',
        width: 180,
    },
    {
        Header: 'Vin',
        accessor: 'acesArticleLinkage.vin.value',
        width: 180,
    },
    {
        Header: 'Power Output',
        accessor: 'acesArticleLinkage.powerOutput.value',
        width: 180,
    },
    {
        Header: 'Designation',
        accessor: 'acesArticleLinkage.designation.value',
        width: 180,
    },
    {
        Header: 'Fuel System Control Type',
        accessor: 'acesArticleLinkage.fuelSystemControlType.value',
        width: 200,
    },
    {
        Header: 'Fuel Delivery Type',
        accessor: 'acesArticleLinkage.fuelDeliveryType.value',
        width: 180,
    },
    {
        Header: 'Fuel Delivery SubType',
        accessor: 'acesArticleLinkage.fuelDeliverySubType.value',
        width: 180,
    },
    {
        Header: 'Fuel System Design',
        accessor: 'acesArticleLinkage.fuelSystemDesign.value',
        width: 180,
    },
    {
        Header: 'Cylinder Head Type',
        accessor: 'acesArticleLinkage.cylinderHeadType.value',
        width: 180,
    },
    {
        Header: 'Aspiration',
        accessor: 'acesArticleLinkage.aspiration.value',
        width: 180,
    },
    {
        Header: 'Ignition System Type',
        accessor: 'acesArticleLinkage.ignitionSystemType.value',
        width: 180,
    },
    {
        Header: 'Fuel Type',
        accessor: 'acesArticleLinkage.fuelType.value',
        width: 180,
    },
    {
        Header: 'Notes',
        accessor: 'acesArticleLinkage.notes',
        Cell: createAccessorArrayDescription,
        width: 500
    },
	{
		Header: 'Life Cycle',
		accessor: 'lifeCycle',
		align: 'left',
        width: 220,
	},
	{
		Header: 'Mfr Label',
		accessor: 'mfrLabel',
		align: 'left',
        width: 220,
	},
]);

const Revalidation = ({userNotifications, icon}) => {

    const [revalidationReport, setRevalidationReport] = useState(null);
    const {setEditBarOpen} = useContext(EditBarContext);
    const {actionInfos, setActionInfos, setActionStaticContent} = useContext(ActionBarContext);
    const {setSnackBarInfo} = useContext(SnackBarContext);

    const [facets, setFacets] = useState([]);
    const [positions, setPositions] = useState({});
    const [isLinkageCustom, setIsLinkageCustom] = useState(false);
    const [partTypeId, setPartTypeId] = useState(0);
    const templateRef = useRef(null);
    const linkageOverviewRef = useRef(null);

    useEffect(() => {        
        getRevalidationLastReport(setRevalidationReport);
        setEditBarOpen(false);

        getSearchFacets(data => {
            setFacets(data);
        });

        const unsubscrible = configureActionBar(defaultActions, actionInfos, setActionInfos, setActionStaticContent);

        return unsubscrible;
    },[]);

    useEffect(() => {
        setActionStaticContent(
        <div style={{display: 'flex', alignItems: 'center'}}>
            <Typography>{`${!!revalidationReport ? `Last Revalidation started on ${convertISOtoLocal(revalidationReport?.startDate)}${revalidationReport?.status > 1 
                ? `, tooks ${parseFloat((new Date(revalidationReport?.endDate).getTime() - new Date(revalidationReport?.startDate).getTime()) / 1000).toFixed(2)}s and ` 
                : ''}${RevalidationReportStatus(revalidationReport?.status)}. ` : ''}`}</Typography>
        </div>);
    },[revalidationReport]);

    useEffect(() => {
        getPositionForId(partTypeId, (positions) => {
            setPositions(positions.reduce(addNameByIdToDict, {}));
        });
    },[partTypeId]);

    const refreshPosition = (onData, partTypeId) => {
        getPositionForId(partTypeId, (positions) => {
            setPositions(positions.reduce(addNameByIdToDict, {}));
            onData();
        });
    }

    const addNameByIdToDict = (dict, pos) => {
        dict[pos.id] = pos.name;
        return dict;
    };

    useEffect(() => {
        getRevalidationLastReport(setRevalidationReport);
        if (!!templateRef) {
            templateRef.current.refreshData();
        }
    }, [userNotifications]);

    const revalidate = () => {
        postRevalidate(d => {
            if (!!d?.data) {
                setSnackBarInfo({
                    open: true,
                    message: "Revalidation started",
                    severity: "success"
                });
                setTimeout(() => {
                    getRevalidationLastReport(setRevalidationReport)
                }, 500);
            } else {
                setSnackBarInfo({
                    open: true,
                    message: "Revalidation is already started",
                    severity: "warning"
                });
            }
        },
        e => {
            setSnackBarInfo({
                open: true,
                message: e?.message,
                severity: "error"
            });
        })
    }

    const defaultActions = 
    [{
        label: "Revalidate",
        name: "revalidate",
        onAction: revalidate
    }];

    const onEditValue = editValue => {
        setIsLinkageCustom(facets.filter(facet => editValue.acesArticleLinkage[facet.properties['FieldTypeName']]?.isCustom).length > 0 || editValue.acesArticleLinkage.positionId > 100000);
        if (partTypeId !== editValue?.partTypeId) {
            setPartTypeId(editValue?.partTypeId);
        }
    }

    const validations = [
        (error) => {
            const equipmentModelError = Boolean(error.acesArticleLinkage.equipmentModel) ? undefined : "Equipment model is required";
            return {'equipment-model': equipmentModelError};
        },
        (error) => {
            const equipmentMfrError = Boolean(error.acesArticleLinkage.equipmentMfr) ? undefined : "Equipment mfr is required";
            return {'mfr': equipmentMfrError};
        },
        (error) => {
            const vehicleTypeError = Boolean(error.acesArticleLinkage.vehicleType) ? undefined : "Vehicle type is required";
            return {'vehicle-type': vehicleTypeError};
        },
        (error) => {
            const positionError = Boolean(error.acesArticleLinkage.positionId) ? undefined : "Position is required";

            if (!!!positionError && !!error?.acesArticleLinkage?.positionId && !!Object.keys(positions)?.length) {
                if (!!!positions[error?.acesArticleLinkage?.positionId]) {
                    return {'position': 'Invalid position'};
                }
            }

            return {'position': positionError};
        },
        (error) => {
            if (linkageOverviewRef && linkageOverviewRef.current) {
                const facetValues = linkageOverviewRef.current.getFacetInfos()?.facetValues;
                
                const facetErrors = facets.filter(x => {
                    
                    const linkageFacetValue = error.acesArticleLinkage[x?.properties?.FieldTypeName]?.id;
                    return linkageFacetValue !== 0 && !!facetValues[x.name]?.length && !!!facetValues[x.name].filter(y => y?.id == linkageFacetValue)?.length  });

                return facetErrors.reduce((acc, cur) => {
                    acc[cur?.name] = `Invalid value for ${cur?.properties?.SourceName}`;
                    return acc;
                }, {});
            }
            return {};
        },
        (error) => {
            const facetErrors = facets.filter(x => {
                const linkageFacetValue = error.acesArticleLinkage[x?.properties?.FieldTypeName]
                return !!!linkageFacetValue?.value && !!linkageFacetValue?.id});

            return facetErrors.reduce((acc, cur) => {
                acc[cur?.name] = `Unknown Id for ${cur?.properties?.SourceName}`;
                return acc;
            }, {});
        },
    ];

    const onSave = (value, resolve) => {
        return new Promise(() => doPostLinkage(value?.acesArticleLinkage, resolve, facets));
    }

    const label = () => isLinkageCustom ? <div style={{display: 'flex'}}>Application&nbsp;<div style={{backgroundColor: 'yellow'}}> (Custom)</div></div> : "Application"
    

    return (
            <TemplateManage
            keyPropertyId="acesArticleLinkage.id"
            label={label}
            saveMessageLabel="Application"
            title="Revalidation Errors"
            titleIcon={icon()}
            columnDefs={createArticleColumnDefs()}
            showNew={false}
            ref={templateRef}
            additionalActions={defaultActions}
            onEditValue={onEditValue}
            getAllObjects={(resolve) => new Promise(() => {
                getRevalidationErrors(d => resolve(d.map(x => ({...x, 
                    errorType: errorTypes[x.errorType] ?? 'Unknown',
                    errorMessage: errorMessageFactory(x)}))))
            })}
            postObject={onSave}
            deleteObject={deleteAcesLinkages}
            validations={validations}
            uniqueId="revalidation-errors-table"
            defaultSortColumn={"articleNumber"}
            customRowProps={row => ({
                    style: {
                        backgroundColor: row.original.isCustom || row.original.positionId > 100000 ? 'yellow' : null,
                    }
                })
            }
            renderInputs={({editValue, setEditValue, errors}) => {

                const notes = !!editValue?.acesArticleLinkage?.notes && !!editValue?.acesArticleLinkage?.notes?.length ? [...editValue.acesArticleLinkage.notes] : [''];
                return (
                    <LinkageOverview
                        isDisable={group => isDisable(group, editValue.acesArticleLinkage)}
                        errors={errors}
                        ref={linkageOverviewRef}
                        linkage={editValue.acesArticleLinkage}
                        facets={facets}
                        setIsLinkageCustom={setIsLinkageCustom}
                        refreshPosition={onData => refreshPosition(onData, editValue?.partTypeId)}
                        handleSelect={(option, facetName) => handleSelect(option, facetName, editValue, setEditValue, setIsLinkageCustom, facets, l => l.acesArticleLinkage)}
                        acesAttributeMatchTypeRender={acesAttributeMatchTypeRender}
                        notes={notes}
                        setNotes={notes => setEditValue({...editValue, acesArticleLinkage: {...editValue.acesArticleLinkage, notes}})}
                        positions={positions}
                        positionId={editValue.acesArticleLinkage.positionId}
                        setPositionId={positionId => setEditValue({...editValue, acesArticleLinkage: {...editValue.acesArticleLinkage, positionId}})}
                        quantity={editValue.acesArticleLinkage.quantity}
                        setQuantity={quantity => setEditValue({...editValue, acesArticleLinkage: {...editValue.acesArticleLinkage, quantity}})}
                    />
                    )
                }
            }
        />
    );
}

export default Revalidation;