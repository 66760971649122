import React, { useEffect, useState, useImperativeHandle } from 'react';
import { IconButton, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Tooltip from '@mui/material/Tooltip';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import GeneralVirtualizedAutocomplete from '../GeneralVirtualizedAutocomplete';
import { Grid } from '@mui/material';
import GeneralTextField from '../GeneralTextField';
import {
    getSearchFacetsValues,
} from '../../services/ApiService';
import _ from 'lodash';
import {AcesAttributeMatchType} from '../../services/LinkageOverviewService';
import AddAcesCustomAttributeDialog from '../dialogs/AddAcesCustomAttributeDialog'

const PositionGroup = 'position-group';
const QuantityGroup = 'quantity-group';
const attributeSelectors = [
    {
        id : 'equipement-base',
        title : 'Equipment Base',
        selectors : [
            {
                facet : 'equipment-model',
                label : 'Equipment Model'
            },
            {
                facet : 'mfr',
                label : 'Equipment Mfr'
            },
            {
                facet : 'vehicle-type',
                label : 'Vehicle Type'
            }
        ]
    },
    {
        id : 'region-group',
        title : 'Region',
        selectors : [
            {
                facet : 'region',
                label : 'Region'
            }
        ]
    },
    {
        id : 'engine',
        title : 'Engine',
        selectors : [
            {
                facet : 'engine-block',
                label : 'Engine block'
            },
            {
                facet : 'engine-bore-stroke',
                label : 'Engine BoreStroke'
            },
            {
                facet : 'engine-mfr-name',
                label : 'Engine Mfr',
            },
            {
                facet : 'aspiration',
                label : 'Aspiration'
            },
            {
                facet : 'power',
                label : 'Power Output',
            },
            {
                facet : 'valves',
                label : 'Valves'
            },
            {
                facet : 'engine-vin',
                label : 'Vin'
            },
            {
                facet : 'engine-version',
                label : 'EngineVersion',
            },
            {
                facet : 'engine-designation',
                label : 'Designation'
            },
            {
                facet : 'cylinder-head-type',
                label : 'CylinderHeadType'
            },
            {
                facet : 'ignition-type',
                label : 'IgnitionSystemType'
            },
            {
                facet : 'fuel-type',
                label : 'FuelType'
            },
            {
                facet : 'fuel-delivery-type',
                label : 'FuelDeliveryType'
            },
            {
                facet : 'fuel-delivery-subtype',
                label : 'FuelDeliverySubType'
            },
            {
                facet : 'fuel-system-control-type',
                label : 'FuelSysControlType'
            },
            {
                facet : 'fuel-system-design-type',
                label : 'FuelSystemDesign'
            }
        ]
    }
];

const defaultFacetInfo = {facetValues: [], selectedAttributes: {}, acesSelection: {}};

const LinkageOverview = React.forwardRef((props, ref) => {

    const {
        linkage,
        facets,
        isDisable,
        handleSelect,
        acesAttributeMatchTypeRender,
        notes,
        setNotes,
        positions,
        refreshPosition,
        positionId,
        setPositionId,
        quantity,
        setQuantity,
        setIsLinkageCustom,
        errors = {},
    } = props;

    const [accordionExpandedStates, setAccordionExpandedStates] = useState({'equipement-base': true});
    const [facetInfo, setFacetInfo] = useState(defaultFacetInfo);
    const [addAcesCustomAttributeDialogProps, setAddAcesCustomAttributeDialogProps] = useState({open: false, attributeFieldName: undefined});
    const {selectedAttributes, facetValues, acesSelection} = facetInfo;
    
    useEffect(() => {
        const selectedFromLinkage = {};
                    facets
                    .forEach(facet => {
                        const acesSelection = linkage[facet.properties['FieldTypeName']];
                        if (acesSelection && acesSelection.value) {
                            selectedFromLinkage[facet.name] = {
                                id : acesSelection.id,
                                value : acesSelection.value,
                                isCustom: linkage[facet.properties['FieldTypeName']]?.isCustom ?? false,
                            }
                        }
                    });
       
        const currentAcesSelection = Object.entries(selectedFromLinkage).filter(x => Boolean(x[1])).reduce((acc, curr) => ({...acc, [curr[0]]: curr[1] ? [curr[1].id] : []}), {});
        if (Object.values(acesSelection).length === 0 || !_.isEqual(currentAcesSelection, acesSelection))
        {
            const effectiveCurrentAcesSelection = Object.entries(selectedFromLinkage).filter(x => Boolean(x[1]) && !x[1].isCustom).reduce((acc, curr) => ({...acc, [curr[0]]: curr[1] ? [curr[1].id] : []}), {});
            setFacetInfo(defaultFacetInfo);
            setAccordionExpandedStates({...accordionExpandedStates, 'equipement-base': true});
            getSearchFacetsValues(effectiveCurrentAcesSelection, (data) => {
                
                const newFacetValues = data.reduce((acc, current) => ({...acc, [current.facet] : [...current.values.map((facetValue) => ({
                    id: facetValue.id,
                    value: facetValue.properties.name,
                    matchType: facetValue.properties.isCustom ? 'custom' : facetValue.properties.matchType,
                    disabled: facetValue.properties.isCustom ? false : facetValue.properties.matchType && facetValue.properties.matchType === AcesAttributeMatchType.Invalid,
                    isCustom: facetValue.properties.isCustom  ?? false,
                }))]
                .sort((a, b) => acesAttributeMatchTypeRender[a.matchType]?.order - acesAttributeMatchTypeRender[b.matchType]?.order || a.value.localeCompare(b.value, undefined, {numeric: true, sensitivity: 'base'}))
                }), {});
                setFacetInfo({
                    facetValues: newFacetValues,
                    selectedAttributes: selectedFromLinkage,
                    acesSelection: currentAcesSelection
                });
            });
        }

    },[facets, linkage]);

    useEffect(() => {
        if (Object.keys(positions)?.length === 1) {
            const newPositionId = Object.keys(positions)[0];
            if (newPositionId !== positionId) {
                setPositionId(newPositionId);
            }
        }
    }, [positions]);

    useImperativeHandle(ref, () => ({
        getFacetInfos: () => facetInfo
    })) 

    const getIndicatorMatchType = (option) => {
        return option && option.matchType ? acesAttributeMatchTypeRender[option.matchType].render + ' ' : '';
    }

    const handleNotes = (e, i) => {
        const value = e.target.value;
        const newNotes = [...notes];
        newNotes[i] = value;
        setNotes(newNotes.filter(x => x));
    };

    const handleAccordionExpandedState = (accordionName, stateValue = undefined) => {
        const newStateValue = stateValue || !Boolean(accordionExpandedStates[accordionName]);
        setAccordionExpandedStates({...accordionExpandedStates, [accordionName]: newStateValue});
    }

    const openAddAcesCustomAttribute = (attributeFieldName) => {
        setAddAcesCustomAttributeDialogProps({attributeFieldName, open: true});
    }

    const closeAddAcesCustomAttribute = () => {
        setAddAcesCustomAttributeDialogProps({attributeFieldName: '', open: false});;
    }

    const onAddCustomAttribute = (newCustomAttribute) => {
        if (newCustomAttribute?.attributeFieldName === 'Position') {
            refreshPosition(() => {
                setPositionId(newCustomAttribute?.acesArticleLinkageCustomAttributeId);
            })
        } 
        else {
            const facetName = facets.find(x => x.properties?.SourceName === newCustomAttribute?.attributeFieldName).name;
            handleSelect({
                id: newCustomAttribute.acesArticleLinkageCustomAttributeId,
                value: newCustomAttribute.attributeValue,
                matchType: 'custom',
                disabled: false,
                isCustom: true,
            }, facetName);
        }
    }

    const handlePositionId = v => {
        const isCustom = facets.filter(facet => linkage[facet.properties['FieldTypeName']]?.isCustom).length > 0 || v > 100000;
        setIsLinkageCustom(isCustom);
        setPositionId((effectivePositions.find(x => x.id === v)?.id || 0));
    }

    const effectivePositions = Object.entries(positions).reduce((d, el, i) => (d[i] = { id: Number(el[0]), value: el[1] }, d), []).sort(x => x.name);

    return (
        <div style={{ width: '100%', marginTop: '9px', overflow: 'auto' }}>
            {
                attributeSelectors.map((group, i) => (
                    <React.Fragment key={`${group.id}-${i}`} >
                        <Accordion 
                            disabled={isDisable(group)}
                            expanded={(accordionExpandedStates[group.id] || false) && !isDisable(group)}
                            onChange={() => handleAccordionExpandedState(group.id)}
                            variant=''
                            sx={{backgroundColor: 'transparent', border: 0}}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`${group.id}-${i}`}
                                id={`${group.id}-${i}`}
                            >
                                <Typography variant="h6">{`${group.title}${i === 0 ? " *" : ""}`}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={2}>
                                    {
                                        group.selectors.map((selector, y) => {
                                            const linkageValue = linkage[facets.find(x => x.name === selector.facet)?.properties?.FieldTypeName];
                                            const isInvalidId = !!!linkageValue?.value && !!linkageValue?.id;
                                            const value = isInvalidId ? `Unknown Id (id = ${linkageValue.id})` : '';
                                            return (
                                            !Boolean(selector.hidden) ?
                                            <Grid item xs={12} key={`${group.id}-${i}-${selector.facet}-${y}`} sx={{display: 'flex'}}>
                                                <GeneralVirtualizedAutocomplete
                                                    options={facetValues[selector.facet] || []}
                                                    value={selectedAttributes[selector.facet]}
                                                    label={selector.label}
                                                    keyId="id"
                                                    keyName="value"
                                                    textFieldInputProps={selectedAttributes[selector.facet]?.isCustom ? {sx: {backgroundColor: 'yellow'}} : {}}
                                                    getOptionLabel={(option) => Boolean(option) ? getIndicatorMatchType(option) + option.value : value}
                                                    getOptionDisabled={option => Boolean(option) && option.disabled}
                                                    setId={(v) => handleSelect(facetValues[selector.facet].find(x => x.id === v), selector.facet)}
                                                    handleError={ (p) => !!errors[selector.facet] }
                                                    getHelperText={ (p) => errors[selector.facet] || '' }
                                                    sx={{flexGrow: 1}}
                                                />
                                                <Tooltip title="Add Custom Ace Attributes">
                                                    <IconButton
                                                        onClick={() => openAddAcesCustomAttribute(facets.find(x => x.name === selector.facet)?.properties?.SourceName)}
                                                    >
                                                        <AddIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                            : null
                                        )})
                                    }
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        {
                            i == 0 ?             
                            <Accordion 
                                expanded={accordionExpandedStates['link'] || false}
                                onChange={() => handleAccordionExpandedState('link')}
                                variant='' 
                                sx={{backgroundColor: 'transparent'}}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Typography variant="h6">Link *</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div style={{ width: '100%' }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}  sx={{display: 'flex'}}>
                                                <GeneralVirtualizedAutocomplete
                                                    options={effectivePositions}
                                                    value={{ id: Number(positionId || 0), value: positions[positionId] || '' }}
                                                    textFieldInputProps={positionId >= 100000 ? {sx: {backgroundColor: 'yellow'}} : {}}
                                                    label="Position"
                                                    key={`${PositionGroup}-position`}
                                                    keyId="id"
                                                    keyName="value"
                                                    setId={handlePositionId}
                                                    handleError={ (p) => !!errors['position'] }
                                                    getHelperText={ (p) => errors['position'] || '' }
                                                    sx={{flexGrow: 1}}
                                                />
                                                <Tooltip title="Add Custom Position">
                                                    <IconButton
                                                        onClick={() => openAddAcesCustomAttribute('Position')}
                                                    >
                                                        <AddIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <GeneralTextField
                                                    label="Quantity"
                                                    keyPropertyName="value"
                                                    size="small"
                                                    fullWidth={true}
                                                    id={`${QuantityGroup}-quantity`}
                                                    type="number"
                                                    itemValue={{value: quantity}}
                                                    setItemValue={(v) => {
                                                        if (Number(v.value) > 0) 
                                                        {
                                                            setQuantity(Number(v.value));
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                </AccordionDetails>
                            </Accordion> : null
                        }
                    </React.Fragment >
                ))
            }
            <Accordion 
                expanded={accordionExpandedStates['notes'] || false}
                onChange={() => handleAccordionExpandedState('notes')}
                variant='' 
                sx={{backgroundColor: 'transparent'}}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography variant="h6">Notes</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{ width: '100%' }}>
                        {
                            notes.map((x, i) =>
                                <div key={i} style={{
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}>
                                    <TextField
                                        style={{ width: '100%' }}
                                        value={x}
                                        onChange={e => handleNotes(e, i)}
                                        />
                                    {
                                        i === notes.length - 1 ?
                                        <IconButton
                                            onClick={() => setNotes([...notes.filter(x => x), '']) }
                                        >
                                            <AddBoxIcon />
                                        </IconButton>
                                        : null
                                    }
                                </div>)
                        }
                    </div>
                </AccordionDetails>
            </Accordion>
            <AddAcesCustomAttributeDialog 
                open={addAcesCustomAttributeDialogProps.open} 
                setOpen={closeAddAcesCustomAttribute} 
                attributeFieldName={addAcesCustomAttributeDialogProps.attributeFieldName}
                executeAction={onAddCustomAttribute}/>
        </div>
    );
});

export default LinkageOverview;