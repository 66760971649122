import { TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

const GeneralDatePicker = (props) => {
    const { 
        keyPropertyName, 
        label, 
        itemValue, 
        setItemValue,
        handleError = () => false,
        getHelperText = () => '',
        ...otherProps 
    } = props;

    const handleChange = (value) => {
        const updatedObj = {...itemValue, [keyPropertyName]: new Date(value).getTime()};
        setItemValue(updatedObj);
    };

    const id = `${keyPropertyName}-id`;

    const getFormattedDate = () => {
        if (itemValue[keyPropertyName]) {
            return new Date(itemValue[keyPropertyName]);
        }
        return null;
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
                id={id}
                label={label}
                inputFormat="yyyy/MM/dd"
                value={getFormattedDate()}
                onChange={handleChange}
                slotProps={{
                    textField: {
                        variant: 'standard',
                        color: 'primary',
                        fullWidth: true,
                        error: handleError(keyPropertyName),
                        helperText: getHelperText(keyPropertyName),
                        InputLabelProps: { shrink: true },
                        ...otherProps
                    }
                }}
            />
        </LocalizationProvider>
    );
};

export default GeneralDatePicker;