import React, { useEffect, useState, useContext, useRef, useImperativeHandle } from 'react';
import { createEffect } from './utilities';
import { JnpDivTable, DivTableStyles } from "jnpsoft-react-utilities/dist/JnpTable";
import { SnackBarContext ,ActionBarContext } from '../context';
import { configureActionBar, executeAction, configureDisabled } from '../services/ActionBarService';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import EditBar from './EditBar';
import ConfirmationDialog from './ConfirmationDialog';
import _ from 'lodash';

const generateMessages = label => ({
    successMessage: `${label} saved`,
    deleteMessage: `${label}(s) deleted`,
    errorSaveMessage: `Unable to save ${label}`,
    errorDeleteMessage: `Unable to delete ${label}`,
    deleteTextTitle: `Delete ${label}`,
    deleteTextContent: `Please confirm the deletion of selected ${label}(s)`,
})

const TemplateManage = React.forwardRef((props, ref) => {

    const {
        keyPropertyId,
        label,
        saveMessageLabel,
        columnDefs,
        createDefaultObject,
        getAllObjects,
        postObject,
        deleteObject,
        showNew = true,
        afterEffect = () => {},
        onEditValue = () => {},
        additionalActions = [],
        renderInputs,
        validations = [],
        ...otherTableProps
    } = props;

    const effectiveLabel = (typeof label === 'function' ? label() : label);
    const messages = generateMessages(saveMessageLabel ?? effectiveLabel);
    const readOnly = !Boolean(renderInputs);

    const {actionInfos, setActionInfos, setActionStaticContent} = useContext(ActionBarContext);
    const {setSnackBarInfo} = useContext(SnackBarContext);

    const [editValue, setEditValue] = useState(null);
    const [allObjects, setAllObjects] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);
    const [openEditSideBar, setOpenEditSideBar] = useState(false);
    const [errors, setErrors] = useState({});
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [focusRowId, setFocusRowId] = useState(-1);
    const [additionalActionsState, setAdditionalActionsState] = useState([]);

    const tableRef = useRef(null);

    useImperativeHandle(ref, () => ({
        refreshData: () => {
            getAllObjects(d => setAllObjects(d ?? []))
        }
    })) 

    useEffect(createEffect(getAllObjects, setAllObjects, afterEffect), []);

    const onNew = () => {
        setOpenEditSideBar(true);
        clearSelection();
        handleEditValue(createDefaultObject());
    };

    const onDelete = () => {
        let objects = allObjects;
        selectedIds.forEach( (id) => {
            deleteObject(id, 
                () => {
                    setSnackBarInfo({
                        open: true,
                        message: messages.deleteMessage,
                        severity: "success"
                    });
                },
                () => {
                    setSnackBarInfo({
                        open: true,
                        message: messages.errorDeleteMessage,
                        severity: "error"
                    });
                });
            objects = objects.filter( (obj) => getValueForPath(obj, keyPropertyId) !== id );
        });
        setAllObjects(objects);
        onEditClose();
    }

    const onSave = () => {
        if (editValue)
        {
            const isNew = !(editValue[keyPropertyId] && editValue[keyPropertyId] > 0);
            const effectiveEditValue = isNew ? {...editValue, [keyPropertyId]: undefined} : editValue;
            postObject(effectiveEditValue, d => {
                getAllObjects((data) => {
                    if (data) {
                        setAllObjects(data);
                        setFocusRowId(d.data[keyPropertyId]);
                        setSnackBarInfo({
                            open: true,
                            message: messages.successMessage,
                            severity: "success"
                        });
                        setEditValue(d.data);
                    }
                });
            },
            error => {
                console.log(error.response.data);
                setSnackBarInfo({
                    open: true,
                    message: [messages.errorSaveMessage, <br/>, error.response.data],
                    severity: "error"
                });
            })
        }
    }

    const setSelectedRows = (selectedFlatRows) => {
        if (selectedFlatRows) {
            const ids = [];
            selectedFlatRows.forEach(el => {
                ids.push(getValueForPath(el.original, keyPropertyId));
            });
            setSelectedIds(ids);
        }
    };

    const getValueForPath = (obj, path) => {
        for (var i=0, path=path.split('.'), len=path.length; i<len; i++){
            obj = obj[path[i]];
        };
        return obj;
    };

    const rowOnClick = (e, i, v) => {
        if (!readOnly) 
        {
            const rowObject = v?.original;
            handleEditValue(rowObject);
            if (!openEditSideBar)
            {
                setOpenEditSideBar(true);
            }
        }
    }

    const onEditClose = () => {
        setOpenEditSideBar(false);
        setEditValue(null);
        clearSelection();
    }

    const handleEditValue = editValue => {        
        setEditValue(editValue);
        onEditValue(editValue);

        setTimeout(() => {
            if (validations.length)
            {
                const errors = validations.map(x => x(editValue)).reduce((acc, c) => ({...acc, ...c}));
                setErrors(errors);
            }
        }, 500);
    }

    const defaultConfigureActionBar = () => {
        let actions = deleteObject
        ? [{
            label: "Delete",
            name: "delete"
        }]
        : [];

        if (!readOnly) {
            if (showNew) {
                actions = [...actions, {
                    label: "New",
                    name: "new",
                }];
            }

            actions = [...actions, {
                label: "Save",
                name: "save"
            }];
        }

        return configureActionBar([...additionalActions, ...actions], actionInfos, setActionInfos, setActionStaticContent);
    }

    const clearSelection = () => {
        if (!!tableRef) {
            tableRef.current.clearSelection();
        }
    }

    useEffect(() => {
        setAdditionalActionsState(additionalActions);
    },[]);

    useEffect(() => {
        defaultConfigureActionBar();
    },[additionalActionsState]);

    useEffect(() => {
        if (!_.isEqual(additionalActions.map(x => ({name: x.name})), additionalActionsState.map(x => ({name: x.name})))) {
            setAdditionalActionsState(additionalActions);
        }
    },[additionalActions]);

    useEffect(() => {
        if (actionInfos.actionClicked === 'delete')
        {
            executeAction(() => setOpenDeleteDialog(true), actionInfos, setActionInfos);
        }
        else if (!readOnly && actionInfos.actionClicked === 'new')
        {
            executeAction(onNew, actionInfos, setActionInfos);
        }
        else if (!readOnly && actionInfos.actionClicked === 'save')
        {
            executeAction(onSave, actionInfos, setActionInfos);
        }
        else {
            const additionalAction = additionalActions.find(x => x.name === actionInfos.actionClicked);

            if (additionalAction && additionalAction.onAction)
            {
                executeAction(() => {
                    const selectedRows = selectedIds.map(id => allObjects.find(obj => getValueForPath(obj, keyPropertyId) === id ));
                    additionalAction.onAction(selectedRows);
                }, actionInfos, setActionInfos);
            }
        }
        
    },[actionInfos.actionClicked]);

    useEffect(() => {
        const disabled = editValue === null || Object.values(errors).filter(x => x).length > 0;
        configureDisabled("save", disabled, actionInfos, setActionInfos);
    },[errors, editValue]);

    useEffect(() => {
        const disabled = selectedIds.length === 0;
        configureDisabled("delete", disabled, actionInfos, setActionInfos);

        const disabledSave = editValue === null || Object.values(errors).filter(x => x).length > 0;
        configureDisabled("save", disabledSave, actionInfos, setActionInfos);

        additionalActions.filter(x => x.disableOnEmptySelection).forEach(x => configureDisabled(x.name, disabled, actionInfos, setActionInfos))

    },[selectedIds]);

    return (
        <div style={{height: 'calc(100% - 20px)'}}>
            <EditBar open={openEditSideBar}>
                <Box sx={{display: 'flex', alignContent: 'center' ,justifyContent: 'space-between', paddingBottom: 2 }}>
                    <Typography variant="h5">
                    {effectiveLabel}
                    </Typography>
                    <IconButton color="inherit" aria-label="close edit" component="span" sx={{padding: 0}} onClick={onEditClose}>
                        <Close />
                    </IconButton>
                </Box>
                <Box >
                {editValue && renderInputs ? renderInputs({editValue, setEditValue: handleEditValue, errors, isUsed: (!!editValue['isUsed'])}) : <React.Fragment></React.Fragment>}
                </Box>
            </EditBar>
            <DivTableStyles>
                <JnpDivTable
                ref={tableRef}
                columns={columnDefs}
                data={allObjects}
                selectable={true}
                disableSelect={ (row) => !!row.original['isUsed'] }
                setSelectedRows={setSelectedRows}
                rowOnClick={rowOnClick}
                focusRowId={focusRowId}
                keyPropertyId={keyPropertyId}
                selectedBackgroundColor='#BEBEBE'
                {...otherTableProps}
                />
            </DivTableStyles>
            <ConfirmationDialog
                    open={openDeleteDialog}
                    setOpen={setOpenDeleteDialog}
                    id="delete"
                    textTitle={messages.deleteTextTitle}
                    textContent={messages.deleteTextContent}
                    confirmButtonLabel="Delete"
                    cancelButtonLabel="Cancel"
                    executeAction={onDelete}
            />
        </div>
    );
});

export default TemplateManage;