//axios jnp-utilities
import axios from 'axios';

const ApiUrl = process.env.REACT_APP_EQUIPMENT_DE_API_URL;

const log = (m) => console.log(m);

const get = (uri, onData = log, onError = log) => {
    axios.get(`${ApiUrl}/${uri}`)
    .then(response => onData(response.data))
    .catch(onError);
};

const post = (uri, postData, onData = log, onError = log, options = {}) => {
    axios.post(`${ApiUrl}/${uri}`, postData, options)
    .then(response => onData(response))
    .catch(onError);
};

const del = (uri, onData = log, onError = log) => {
    axios.delete(`${ApiUrl}/${uri}`)
    .then(response => onData(response))
    .catch(onError);
};

export const getAllArticles = (onData, onError) => {
    get('api/article', onData, onError);
};

export const getArticleForId = (articleId, onData, onError) => {
    get(`api/article/${articleId}`, onData, onError);
};

export const getAllProductLines = (onData, onError) => {
    get('api/productline', onData, onError);
};

export const getProductLineForId = (prodLineId, onData, onError) => {
    get(`api/productline/${prodLineId}`, onData, onError)
};

export const getAllPartTypes = (onData, onError) => {
    get('api/pcdb/parttype', onData, onError);
};

export const getPositionForId = (partTypeId, onData, onError) => {
    get('api/pcdb/position?parttypeid='+partTypeId, onData, onError);
};

export const getAllMfrLabels = (onData, onError) => {
    get('api/mfrlabel', onData, onError);
};

export const getMfrLabelForId = (mfrLabelId, onData, onError) => {
    get(`api/mfrlabel/${mfrLabelId}`, onData, onError);
};

export const getAllLifeCycles = (onData, onError) => {
    get('api/lifecycle', onData, onError);
};

export const getAllAutocareHeader = (onData, onError) => {
     get('api/autocareHeader', onData, onError);
};

export const getAutocareHeaderId = (autocareHeaderId, onData, onError) => {
     get(`api/autocareHeader/${autocareHeaderId}`, onData, onError);
};

export const getLifeCycleForId = (lifeCycleId, onData, onError) => {
    get(`api/lifecycle/${lifeCycleId}`, onData, onError);
};

export const getCustomAcesAttribute = (onData, onError) => {
    get(`api/AcesArticleLinkagesCustomAttributes`, onData, onError);
};

export const getCustomAcesAttributeFieldNames = (onData, onError) => {
    get(`api/AcesArticleLinkagesCustomAttributes/field-names`, onData, onError);
};

export const postArticle = (postData, onData, onError) => {
    post('api/article', postData, onData, onError);
};

export const postAutocareHeader = (postData, onData, onError) => {
    post('api/autocareheader',postData,onData,onError);
};

export const postLifeCycle = (postData, onData, onError) => {
    post('api/lifecycle', postData, onData, onError);
}

export const postMfrLabel = (postData, onData, onError) => {
    post('api/mfrlabel', postData, onData, onError);
}

export const postProductLine = (postData, onData, onError) => {
    post('api/productline', postData, onData, onError);
}

export const postCustomAcesAttribute = (postData, onData, onError) => {
    post('api/AcesArticleLinkagesCustomAttributes', postData, onData, onError);
}

export const deleteAutocareHeader = (autocareHeaderId, onData, onError) => {
    del(`api/autocareHeader/${autocareHeaderId}`, onData, onError);
}

export const deleteProductLine = (prodLineId, onData, onError) => {
    del(`api/productline/${prodLineId}`, onData, onError);
}

export const deleteMfrLabel = (mfrLabelId, onData, onError) => {
    del(`api/mfrlabel/${mfrLabelId}`, onData, onError);
}

export const deleteLifeCycle = (lifeCycleId, onData, onError) => {
    del(`api/lifecycle/${lifeCycleId}`, onData, onError);
}

export const deleteArticle = (articleId, onData, onError) => {
    del(`api/article/${articleId}`, onData, onError);
}

export const getAllAcesArticleLinkage = (onData, onError) => {
    get('api/acesArticleLinkage', onData, onError);
}

export const getAcesArticleLinkagesForArticleId = (articleId, onData, onError) => {
    get(`api/acesArticleLinkage/by-article?articleId=${articleId}`, onData, onError);
}

export const getAllEquipmentModel = (onData, onError) => {
    get('api/vcdb/equipmentmodel', onData, onError);
}

export const getAllMfr = (onData, onError) => {
    get('api/vcdb/mfr', onData, onError);
}

export const getAllVehicleType = (onData, onError) => {
    get('api/vcdb/vehicletype', onData, onError);
}

export const getAllRegion = (onData, onError) => {
    get('api/vcdb/region', onData, onError);
}

export const getAllEngineBlock = (onData, onError) => {
    get('api/vcdb/engineblock', onData, onError);
}

export const getAllEngineBoreStroke = (onData, onError) => {
    get('api/vcdb/engineborestroke', onData, onError);
}

export const getAllValves = (onData, onError) => {
    get('api/vcdb/valves', onData, onError);
}

export const getAllVin = (onData, onError) => {
    get('api/vcdb/enginevin', onData, onError);
}

export const getAllDesignation = (onData, onError) => {
    get('api/vcdb/enginedesignation', onData, onError);
}

export const getAllFuelSystemControlType = (onData, onError) => {
    get('api/vcdb/fuelsystemcontroltype', onData, onError);
}

export const getAllFuelDeliveryType = (onData, onError) => {
    get('api/vcdb/fueldeliverytype', onData, onError);
}

export const getAllFuelDeliverySubType = (onData, onError) => {
    get('api/vcdb/fueldeliverysubtype', onData, onError);
}

export const getAllFuelSystemDesign = (onData, onError) => {
    get('api/vcdb/fuelsystemdesign', onData, onError);
}

export const getAllCylinderHeadType = (onData, onError) => {
    get('api/vcdb/cylinderheadtype', onData, onError);
}

export const getAllAspiration = (onData, onError) => {
    get('api/vcdb/aspiration', onData, onError);
}

export const getAllIgnitionSystemType = (onData, onError) => {
    get('api/vcdb/ignitionsystemtype', onData, onError);
}

export const getAllFuelType = (onData, onError) => {
    get('api/vcdb/fueltype', onData, onError);
}

export const postAcesArticleLinkage = (postData, onData, onError) => {
    post('api/acesArticleLinkage', postData, onData, onError);
}

export const getAllBrands = (onData, onError) => {
    get('api/brand', onData, onError);
}

export const getBrandForId = (brandId, onData, onError) => {
    get(`api/brand/${brandId}`, onData, onError);
}

export const postBrand = (postData, onData, onError) => {
    post('api/brand', postData, onData, onError);
}

export const deleteBrand = (brandId, onData, onError) => {
    del(`api/brand/${brandId}`, onData, onError);
}

export const deleteAcesLinkages = (acesLinkageId, onData, onError) => {
    del(`api/AcesArticleLinkage/${acesLinkageId}`, onData, onError);
}

export const getSearchFacets = (onData, onError) => get('api/vcdb/facets', onData, onError);

export const getSearchFacetsValues = (filterValues, onData, onError) => {
    const queryParameters = Object.entries(filterValues).map(x => x[1].map(y => `filters[${x[0]}]=${y}`).filter(Boolean).join('&'));
    const queryString = queryParameters.filter(Boolean).join('&');
    const url = `api/vcdb/facets/values`;
    get(`${url}?${queryString}`, onData, onError);
}

export const postImportEXCEL = (importConfigurationId, sheetName, file, onData, onError) => {
    const url = "api/EquipmentImport/excel";
    postImportConvert(importConfigurationId, file, url, onData, onError, sheetName);
}

export const postImportXML = (importConfigurationId, file, onData, onError) => {
    const url = "api/EquipmentImport/xml";
    postImportConvert(importConfigurationId, file, url, onData, onError);
}

export const postConvertXmlToExcel = (importConfigurationId, file, onData, onError) => {
    const url = "api/EquipmentConvert/xml-to-excel";
    postImportConvert(importConfigurationId, file, url, onData, onError);
}

export const postConvertExcelToXml = (importConfigurationId, sheetName, file, onData, onError) => {
    const url = "api/EquipmentConvert/excel-to-xml";
    postImportConvert(importConfigurationId, file, url, onData, onError, sheetName);
}

const postImportConvert = (importConfigurationId, file, url, onData, onError, sheetName = '') => {
    const options = {
        headers: {
            'content-type': 'multipart/form-data',
        },
    };
    
    var bodyFormData = new FormData();
    bodyFormData.append("importConfigurationId", importConfigurationId);
    bodyFormData.append("file", file);
    bodyFormData.append("sheetName", sheetName)

    post(url, bodyFormData, onData, onError, options);
};

export const getAllExportConfigs = (onData, onError) => {
    get('api/exportConfiguration', onData, onError);
}

export const getExportConfigForId = (exportConfigurationId, onData, onError) => {
    get(`api/exportConfiguration/${exportConfigurationId}`, onData, onError);
}

export const postExportConfig = (postData, onData, onError) => {
    post('api/exportConfiguration', postData, onData, onError);
}

export const deleteExportConfig = (exportConfigurationId, onData, onError) => {
    del(`api/exportConfiguration/${exportConfigurationId}`, onData, onError);
}

export const exportEXCEL = (exportConfigurationId, onData, onError) => {
    post(`api/EquipmentExport/ExportAcesExcel/${exportConfigurationId}`, {}, onData, onError);
}

export const exportXML = (exportConfigurationId, onData, onError) => {
    post(`api/EquipmentExport/SerializeAcesXml/${exportConfigurationId}`, {}, onData, onError);
}

export const getVcdbVersion = (onData, onError) => {
    get('api/vcdb/version', onData, onError);
}

export const getPcdbVersion = (onData, onError) => {
    get('api/pcdb/version', onData, onError);
}

export const getAllAAIABrands = (onData, onError) => {
    get('api/aaiabrand', onData, onError);
}

export const getGlobalAcesArticleLinkages = (onData, onError) => {
    get('api/globalacesarticlelinkage', onData, onError);
};

export const getAllImportConfigs = (onData, onError) => {
    get('api/importConfiguration', onData, onError);
}

export const getImportConfigForId = (importConfigurationId, onData, onError) => {
    get(`api/importConfiguration/${importConfigurationId}`, onData, onError);
}

export const postImportConfig = (postData, onData, onError) => {
    post('api/importConfiguration', postData, onData, onError);
}

export const deleteImportConfig = (importConfigurationId, onData, onError) => {
    del(`api/importConfiguration/${importConfigurationId}`, onData, onError);
}

export const getFiles = (onData, onError) => {
    get('api/datasupplier/files', onData, onError);
};

export const download = (dataSupplierFileId, onData, onError) => {
    post(`api/EquipmentExport/download/${dataSupplierFileId}`, {}, onData, onError, {responseType: 'arraybuffer'});
}

export const deleteFile = (dataSupplierFileId, onData, onError) => {
    del(`api/EquipmentExport/delete/${dataSupplierFileId}`, onData, onError);
}

export const getImportReports = (onData, onError) => {
    get("api/EquipmentImport", onData, onError);
};

export const deleteImportReport = (importReportId, onData, onError) => {
    del(`api/EquipmentImport/${importReportId}`, onData, onError);
}

export const deleteCustomAcesAttribute = (acesArticleLinkageCustomAttributeId, onData, onError) => {
    del(`api/AcesArticleLinkagesCustomAttributes/${acesArticleLinkageCustomAttributeId}`, onData, onError);
}

export const getRevalidationErrors = (onData, onError) => {
    get('api/Revalidation/errors', onData, onError);
};

export const postRevalidate = (onData, onError) => {
    post('api/vcdb/revalidation', {}, onData, onError);
};

export const getRevalidationLastReport = (onData, onError) => {
    get('api/Revalidation/report', onData, onError);
};