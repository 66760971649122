import { createAccessorArrayDescription } from '../utilities';

export const createColumnDefs = (positions) => ([
    {
        Header: 'Equipment Model',
        accessor: 'equipmentModel.value',
        width: 180,
    },
    {
        Header: 'Equipment Mfr',
        accessor: 'equipmentMfr.value',
        width: 180,
    },
    {
        Header: 'Vehicle Type',
        accessor: 'vehicleType.value',
        width: 180,
    },
    {
        Header: 'Region',
        accessor: 'region.value',
        width: 150,
    },
    {
        Header: 'Position',
        accessor: 'positionId',
        Cell: ({value}) => positions[value],
        width: 180,
    },
    {
        Header: 'Quantity',
        accessor: 'quantity',
        minWidth: 100,
        width: 100,
    },
    {
        Header: 'Engine Block',
        accessor: 'engineBlock.value',
        width: 180,
    },
    {
        Header: 'Engine BoreStroke',
        accessor: 'engineBoreStroke.value',
        width: 180,
    },
    {
        Header: 'Valves',
        accessor: 'valves.value',
        width: 180,
    },
    {
        Header: 'Vin',
        accessor: 'vin.value',
        width: 180,
    },
    {
        Header: 'Engine Version',
        accessor: 'engineVersion.value',
        width: 180,
    },
    {
        Header: 'Designation',
        accessor: 'designation.value',
        width: 180,
    },
    {
        Header: 'Power Output',
        accessor: 'powerOutput.value',
        width: 180,
    },
    {
        Header: 'Engine Mfr',
        accessor: 'engineMfr.value',
        width: 180,
    },
    {
        Header: 'FuelSystemControlType',
        accessor: 'fuelSystemControlType.value',
        width: 200
    },
    {
        Header: 'FuelDeliveryType',
        accessor: 'fuelDeliveryType.value',
        width: 180,
    },
    {
        Header: 'FuelDeliverySubType',
        accessor: 'fuelDeliverySubType.value',
        width: 180,
    },
    {
        Header: 'FuelSystemDesign',
        accessor: 'fuelSystemDesign.value',
        width: 180,
    },
    {
        Header: 'CylinderHeadType',
        accessor: 'cylinderHeadType.value',
        width: 180,
    },
    {
        Header: 'Aspiration',
        accessor: 'aspiration.value',
        width: 180,
    },
    {
        Header: 'IgnitionSystemType',
        accessor: 'ignitionSystemType.value',
        width: 180,
    },
    {
        Header: 'FuelType',
        accessor: 'fuelType.value',
        width: 180,
    },
    {
        Header: 'Notes',
        accessor: 'notes',
        Cell: createAccessorArrayDescription,
        width: 500
    },
]);