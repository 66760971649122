export const setToLocalStorage = (uniqueId, obj) => {
    let value = obj;

    if (value && typeof(value)== 'object')
    {
        value = JSON.stringify(obj);
    }

    if (value !== undefined && value !== null)
    {
        localStorage.setItem(uniqueId, value);
    }
}

export const getToLocalStorage = (uniqueId) => {

    const rawValue = localStorage.getItem(uniqueId);

    if (rawValue && typeof(rawValue) === 'string')
    {
        try {
            return JSON.parse(rawValue);
        } catch (e) { }
    }

    return rawValue;
}
