import React, {useState, useMemo, useEffect} from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import Grid from "@mui/material/Grid"
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import ActionBar from './ActionBar';
import { HomeLink, Links, Routes } from './routes';

import { SnackBarContext, ActionBarContext, EditBarContext } from '../context';
import { setToLocalStorage, getToLocalStorage } from '../services/InternalStorageService';
import { MainSideBar, Notification } from '../constants/InternalStorageKeys';
import { EditBarWidth } from './EditBar';
import MyProducts from './article/MyProducts';
import {useHub, useClientMethod} from 'react-use-signalr';
import {signalRConnection} from '../services/NotificationHubService';
import DrawerLinkButton from './DrawerLinkButton';
import {convertISOtoLocal} from 'jnpsoft-react-utilities/dist/JnpTable/Commons';
import zIndex from '@mui/material/styles/zIndex';

const DrawerWidth = 300;
const zIndexDrawerMenu = 1200;
const FooterHeight = 35;

const Main = (props) => {

	const {setApplicationRoutes} = props;

	useEffect(() => {
		const applicationRoutes = [
			...Routes.map((r) => ({
				path: r.route,
				element: 
					<WrappedRouteElement
					 element={r.target && r.target({...r})} />})),
			...Links.map((r) => ({
				path: r.route,
				element: 
					<WrappedRouteElement
						element={r.target && r.target({...r})} />})),
			{
				path: HomeLink.route,
				element: 
					<WrappedRouteElement
						element={<MyProducts />} />}
		  ];

		  setApplicationRoutes(applicationRoutes);
		}, []);

	return (<div>loading...</div>)
}

const WrappedRouteElement = (props) => {

	const {element, userInfo} = props;

	const initialOpen = Boolean(getToLocalStorage(MainSideBar)) ?? false;

	const [open, setOpen] = useState(initialOpen);

	const [snackBarInfo, setSnackBarInfo] = useState({open: false, message: "", severity: "info"});
	const [actionInfos, setActionInfos] = useState({actions: [], actionClicked: null});
	const [userNotifications, setUserNotifications] = useState([]);
	const [editBarOpen, setEditBarOpen] = useState(false);
	const [actionStaticContent, setActionStaticContent] = useState(null);
	
	const {hubConnectionState, error} = useHub(signalRConnection);

	useEffect(() => {
		if (hubConnectionState === 'Connected') {
			console.log('Connected to notification service!');
		}
	},[hubConnectionState, error]);

	 useEffect(() => {
	 	if (userInfo?.username?.length) {
	 		const userId = userInfo.username ?? '_';
	 		const userNotifications = getToLocalStorage(`${userId}-${Notification}`) ?? [];
	 		setUserNotifications([...userNotifications]);
	 	}
	}, [userInfo.username])

	useEffect(() => {
		const userId = userInfo.username ?? '_';
		setToLocalStorage(`${userId}-${Notification}`, userNotifications);
	},[userNotifications]);

	useClientMethod(signalRConnection, "ReceiveNotifications", (username, message) => {
		const newNotifications = [...userNotifications, `${message} | ${convertISOtoLocal(new Date(Date.now()).toISOString())}`];
		setUserNotifications(newNotifications);
	});

	const snackBarContextValue = useMemo(() => ({
		snackBarInfo,
		setSnackBarInfo,
	}),[snackBarInfo]);

	const actionBarContextValue = useMemo(() => ({
		actionInfos,
		setActionInfos,
		actionStaticContent,
		setActionStaticContent,
	}),[actionInfos, actionStaticContent]);

	const editBarContextValue = useMemo(() => ({
		editBarOpen,
		setEditBarOpen,
	}),[editBarOpen]);

	const handleOpen = e => {
		const newOpenValue = !open;
		setOpen(newOpenValue);
		setToLocalStorage(MainSideBar, newOpenValue);
	};
	const handleSnackBarOnClose = () => setSnackBarInfo({...snackBarInfo, open: false, message: ""});

	const drawerHandleStyle  = {
		"& .MuiDrawer-paper": {
			paddingTop: 6,
			width: open ? DrawerWidth : 57,
			zIndex: 1,
			backgroundColor: '#F5F5F5',
			transition: 'width 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, margin 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
			...(open && {
				"& span.MuiTypography-displayBlock": {
					display:"none"
				},
				"& div.MuiListItemIcon-root": {
					minWidth:"0px",
				}
			})
		}
	};

	const contentShifStyle = {
		height: `calc(100% - ${FooterHeight + 30}px)`,
		maxHeight: `calc(100% - ${FooterHeight + 30}px)`,
		marginLeft: open && !editBarOpen || open && editBarOpen ? DrawerWidth : 57,
		marginRight: !open && editBarOpen || open && editBarOpen ? EditBarWidth : 0,
		width: open && !editBarOpen ? `calc(100% - ${DrawerWidth}px)` : !open && editBarOpen ? `calc(100% - ${EditBarWidth + 57}px)` : open && editBarOpen ? `calc(100% - ${(DrawerWidth + EditBarWidth)}px)` : `calc(100% - ${57}px)`,
		padding: '8px',
		transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
	};
	

    return (
		<SnackBarContext.Provider value={snackBarContextValue}>
				<div style={{
					height: '100%',
					overflowY: 'hidden',
				}}>
					<CssBaseline />
					<Drawer 
						variant="permanent"
						sx={drawerHandleStyle}
					>					
						<div style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'flex-end',
						}}></div>
						<div style={{ textAlign: open ? "right" : "center"}}>
							<IconButton  onClick={handleOpen}>
							{
								open ? <ChevronLeftIcon/> : <ChevronRightIcon/>
							}
							</IconButton>
						</div>
						<List>
								<DrawerLinkButton linkInfo={HomeLink} />

						{
							Links.map((x, i) => <DrawerLinkButton key={i} linkInfo={x} />)
						}
						</List>
					</Drawer>
					<EditBarContext.Provider value={editBarContextValue}>
					<ActionBarContext.Provider value={actionBarContextValue}>
						<main 
							style={contentShifStyle}>
							<ActionBar 
								actionInfos={actionInfos} 
								setActionInfos={setActionInfos} 
								notifications={userNotifications}
								setNotifications={setUserNotifications}
								staticContent={actionStaticContent}/>
							{React.cloneElement(element)}
						</main>
					</ActionBarContext.Provider>
					</EditBarContext.Provider>
					<Snackbar open={snackBarInfo.open} autoHideDuration={7000} onClose={handleSnackBarOnClose}>
						<Alert 
							onClose={handleSnackBarOnClose}
							severity={snackBarInfo.severity || 'info'}
							variant="filled">
							{snackBarInfo.message}
						</Alert>
					</Snackbar>
				</div>
		</SnackBarContext.Provider>
    );
}

export default Main;