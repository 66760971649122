import React from "react";

import ListItemButton  from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';

import {useNavigate, useLocation} from "react-router-dom";

const DrawerLinkButton = ({linkInfo}) => {

  const navigate = useNavigate();
  const location = useLocation();

  const navigateTo = route => navigate(route);

  return (
  <Tooltip title={linkInfo.name}>
    <ListItemButton selected={location.pathname === `/${linkInfo.route}`} onClick={() => navigateTo(`/${linkInfo.route}`)}>
      <ListItemIcon>{linkInfo.icon()}</ListItemIcon>
      <ListItemText sx={{height: 24, paddingLeft: 2}}>{linkInfo.name}</ListItemText>
    </ListItemButton>
  </Tooltip>
  )
}

export default DrawerLinkButton;