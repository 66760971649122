import { HubConnectionBuilder, HttpTransportType } from "@microsoft/signalr";

export const signalRConnection =  new HubConnectionBuilder()
  .withUrl(`${process.env.REACT_APP_EQUIPMENT_DE_API_URL}/equipmenthub`, {
    skipNegotiation: true,
    transport: HttpTransportType.WebSockets,
    accessTokenFactory: () => {
      const oktaTokenStorage = localStorage.getItem("okta-token-storage");

      if (oktaTokenStorage) {
        const oktaTokenStorageObj = JSON.parse(oktaTokenStorage);
        return oktaTokenStorageObj?.['accessToken']?.['accessToken'];
      }

      return null;
    },
  })
  //.withAutomaticReconnect()
  .build();