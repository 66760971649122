import { TextField } from '@mui/material';

const GeneralTextField = (props) => {

    const { 
        keyPropertyName, 
        label, 
        itemValue, 
        setItemValue,
        handleError = () => false,
        getHelperText = () => '',
        ...otherProps } = props;

    const handleChange = (evt) => {
        const val = evt.target.value;
        const updatedObj = {...itemValue, [keyPropertyName]:val};
        setItemValue(updatedObj);
    };

    const id = `${keyPropertyName}-id`;

    return (
        <TextField
            id={id}
            label={label}
            value={itemValue[keyPropertyName] ?? ""}
            onChange={handleChange}
            color="primary"
            variant='standard'
            fullWidth
            error={handleError(keyPropertyName)}
            helperText={getHelperText(keyPropertyName)}
            InputLabelProps={{ shrink: true }}
            {...otherProps}/>
    );
};

export default GeneralTextField;